import React, { useState } from "react";
import { list } from "../../data/Data";

const RecentCard = () => {
  const [selectedProperty, setSelectedProperty] = useState(null); // State to track selected property

  const toggleDetails = (index) => {
    // Toggle between showing and hiding details
    if (selectedProperty === index) {
      setSelectedProperty(null); // Collapse if clicked again
    } else {
      setSelectedProperty(index); // Expand the clicked property
    }
  };

  return (
    <>
      <div className="content mtop">
        {list.map((val, index) => {
          const {
            cover,
            images,
            category,
            location,
            name,
            price,
            type,
            description,
            features,
            additionalDetails, // Added for the new detailed descriptions
          } = val;

          return (
            <div className="box shadow recent vertical" key={index}>
              <div className="img">
                <img src={cover} alt={name} />
              </div>
              <div className="text">
                <div className="category flex">
                  <span
                    style={{
                      background:
                        category === "For Sale" ? "#25b5791a" : "#ff98001a",
                      color: category === "For Sale" ? "#25b579" : "#ff9800",
                    }}
                  >
                    {category}
                  </span>
                  <i className="fa fa-heart"></i>
                </div>
                <h4>{name}</h4>
                <p>
                  <i className="fa fa-location-dot"></i> {location}
                </p>

                {/* Button to toggle property details */}
                <button
                  className="btn-show-details"
                  onClick={() => toggleDetails(index)}
                >
                  {selectedProperty === index
                    ? "Hide Details"
                    : "Show Details"}
                </button>

                {/* Conditionally render details */}
                {selectedProperty === index && (
                  <div className="property-details">
                    <p>{description}</p>
                    <div className="additional-images">
                      {/* Display additional images in a horizontal row */}
                      {images.map((img, i) => (
                        <img key={i} src={img} alt={`${name}-${i}`} />
                      ))}
                    </div>
                    <div className="additional-info">
                      <h5>Additional Details:</h5>
                      <p>{additionalDetails}</p>
                    </div>
                    <ul>
                      {features.map((feature, idx) => (
                        <li key={idx}>{feature}</li>
                      ))}
                    </ul>
                    <div className="button flex">
                      <div>
                        <button className="btn2">{price}</button>
                        <label htmlFor="">/sqm</label>
                      </div>
                      <span>{type}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RecentCard;
