import React from "react"
import img from "../images/pricing.jpg"
import Back from "../common/Back"
import "./contact.css"

const Contact = () => {
  return (
    <>
      <section className="contact mb">
        <Back name="Contact Us" title="Get Help & Friendly Support" cover={img} />
        <div className="container">
          <div className="contact-info shadow">
            <h4>Reach Out to Us</h4>
            <p className="contact-detail">
              📞 Call/WhatsApp: <a href="tel:+254704590949">0704 590 949</a>
            </p>
            <p className="contact-detail">
              ✉️ Email: <a href="mailto:dazeproperties121@gmail.com">dazeproperties121@gmail.com</a>
            </p>
            <p className="contact-message">
              We’re here to assist you with any questions or support you need. Don’t hesitate to get in touch!
            </p>
          </div>
        </div>
      </section>
    </>
  );
};



export default Contact
