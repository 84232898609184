export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
  {
    text: "services",
    path: "/services",
  },
  {
    text: "blog",
    path: "/blog",
  },
  
  {
    text: "contact",
    path: "/contact",
  },
]
export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Family House",
    total: "122 Property",
  },
  
  {
    cover: "../images/hero/h3.png",
    name: "Apartment",
    total: "300 Property",
  },
  {
    cover: "../images/hero/h4.png",
    name: " Studio",
    total: "80 Property",
  },
  {
    cover: "../images/hero/h6.png",
    name: "Villa & Condo",
    total: "80 Property",
  },
]
export const list = [
  {
    id: 4,
    cover: "../images/list/Lav1.jpeg", // Main image
    images: [
      "../images/list/lav2.jpeg",
      "../images/list/lav3.jpeg",
      "../images/list/lav4.jpeg",
      "../images/list/lav5.jpeg",
      "../images/list/lav6.jpeg",
      "../images/list/lav7.jpeg",
      "../images/list/lav8.jpeg",
    ], // Additional images
    name: "Modern Apartments  in Lavington",
    location: "Lavington, Nairobi",
    category: "For Sale",
    price: "Ksh. 8.8M",
    type: "",
    description: "",
    features: [
      "2 Beds  Master Ensuite",
      "Ideal for Investment",
      "Kitchen fitted with Hood and Cooker",
      "We procure tenants on behalf of investors",
      "Proximity to Malls, Schools, Hospitals",
      "FLEXIBLE Payment Plan (Installments)",
      "Bank Mortgage options available",
      "Clean Mother Title",
      "AMENITIES (Intercom,High Tech Security Features ,Children Play Area ,Elegant Modern Gym ,Swimming Pool ,Solar Water Heater ,Back up Generator  ,Borehole & City Council Water  ,Ample Parking ,CCTV Surveillance and Well Landscaped compound)",
    ],
   
  },
  {
    id: 5,
    cover: "../images/list/l1.jpeg", // Main image
    images: [
      "../images/list/l2.jpeg",
      "../images/list/l3.jpeg",
      "../images/list/l4.jpeg",
      "../images/list/l5.jpeg",
      "../images/list/l6.jpeg",
      "../images/list/l7.jpeg",
     
    ], // Additional images
    name: "Modern Apartments  in Lavington ",
    location: "Lavington, Nairobi",
    category: "For Sale",
    price: " Ksh. 7M",
    type: "",
    features: [
      "1 Bed  ",
      "Ideal for Investment",
      "Kitchen fitted with Hood and Cooker",
      "We procure tenants on behalf of investors",
      "Proximity to Malls, Schools, Hospitals",
      "FLEXIBLE Payment Plan (Installments)",
      "Bank Mortgage options available",
      "Clean Mother Title",
      "AMENITIES (Intercom,High Tech Security Features ,Children Play Area ,Elegant Modern Gym ,Swimming Pool ,Solar Water Heater ,Back up Generator  ,Borehole & City Council Water  ,Ample Parking ,CCTV Surveillance and Well Landscaped compound)",
    ],
  },
  {
    id: 3,
    cover: "../images/list/w1.jpeg", // Main image
    images: [
      "../images/list/w2.jpeg",
      "../images/list/w3.jpeg",
      "../images/list/w4.jpeg",
      "../images/list/w5.jpeg",
      "../images/list/w6.jpeg",
      "../images/list/w7.jpeg",
      "../images/list/w8.jpeg",
      "../images/list/w9.jpeg",
    ], // Additional images
    name: "Modern Apartments in Westlands, along Mogotio Road near GTC Mall, Villarosa Kempinski and Sarit Center ",
    location: "Westlands, Nairobi",
    category: "For Sale",
    price: "Ksh. 11.7M",
    type: "",
    features: [
      "Comprises of......",
      " Two bedroom (86.77 sqm from 11.7M)(89.27sqm from 12M)(95.46sqm from 12.5M)(95.96sqm from 12.6M)",
      " Three Bedroom + DSQ (139.95sqm from 17.7M )(140.42sqm from 17.9M)(141.84sqm from 18.1M)(142.39sqm from 18.2M)",
      "AMENITIES (Heated rooftop swimming pool ,Fully Equipped Modern Gym ,Children Play Area ,Rooftop Terrace ,Resting Lounge & Cafe ,Back up Generator  ,Borehole & City Council Water  ,Ample Parking ,Ample Parking Spaces (6levels),CCTV Surveillance and Well Landscaped compound)",
    ],
  },
  {
    id: 1,
    cover: "../images/list/kil1.jpeg", // Main image
    images: [
      "../images/list/kil2.jpeg",
      "../images/list/kil3.jpeg",
      "../images/list/kil4.jpeg",
      "../images/list/kil5.jpeg",
      "../images/list/kil6.jpeg",
      "../images/list/kil7.jpeg",
      "../images/list/kil8.jpeg",
      
    ], // Additional images
    name: "Luxurious Apartments  in Kilimani ",
    location: "Kilimani, Nairobi",
    category: "For Sale",
    price: "From Ksh. 22M",
    type: "",
    features: [
      "Comprises of......",
      "  3 Bed + DSQ all En-suite (202 sqm from 22M)",
      "  4 Bed + DSQ all En-suite (230 sqm from 25.8M)",
      "  5 Bed + DSQ all En-suite (302 sqm from 33M)",
      "AMENITIES (Intercom, High tech security Features,children play Area, Elegant Modern Gym, Heated Swimming Pool, Back up Generator, Borehole & City Council Water, Ample Parking,CCTV Survrilance)",
    ],
  },
  {
    id: 2,
    cover: "../images/list/k4.jpeg", // Main image
    images: [
      
      "../images/list/k3.jpeg",
      "../images/list/k1.jpeg",
      "../images/list/k5.jpeg",
      "../images/list/k6.jpeg",
      "../images/list/k7.jpeg",
      "../images/list/k8.jpeg",
      
    ], // Additional images
    name: "Modern Apartments  in Kilimani ",
    location: "Kilimani, Nairobi",
    category: "For Sale",
    price: "From Ksh. 14M",
    type: "",
    features: [
      "Comprises of......",
      "  2 Beds Master En-suite (117 sqm from 14M)",
      "  3 Bed + DSQ all En-suite (173 sqm from 21M)",
     
      "AMENITIES (Intercom, High tech security Features,children play Area, Elegant Modern Gym, Heated Swimming Pool, Back up Generator, Borehole & City Council Water, Ample Parking,CCTV Survrilance)",
    ],
  },
  
  {
    id: 6,
    cover: "../images/list/le1.jpeg", // Main image
    images: [
      "../images/list/le2.jpeg",
      "../images/list/le3.jpeg",
      "../images/list/le4.jpeg",
      "../images/list/le5.jpeg",
      "../images/list/le6.jpeg",
      "../images/list/le7.jpeg",
      "../images/list/le8.jpeg",
      
    ], // Additional images
    name: "Exquisite Apartment  in Kileleshwa ",
    location: "Kileleshwa, Nairobi",
    category: "For Sale",
    price: "From Ksh. 24M",
    type: "",
    features: [
      "Comprises of......",
      "  3 Bed + DSQ all En-suite (205 sqm from 24M)",
      "  4 Bed + DSQ all En-suite (228 sqm from 26M)",
      "  5 Bed + DSQ all En-suite (367 sqm from 41M)",
      "Features(Balconies both in Master bedroom and Livingroom, Gypsum ceiling with LED Recessed Lights, Master Bedroom: Walk-in Closet, bathtub +shower cubicle, Detached Servants Quarter, All Bedrooms Ensuite, fitted with wardrobe ,  Modern Kitchen with option of closing , Laundry area with provision for washing machine  )",
     
      "AMENITIES (Intercom, High tech security Features,children play Area, Elegant Modern Gym, Heated Swimming Pool, Back up Generator, Borehole & City Council Water, Ample Parking,CCTV Survrilance)",
    ],
  },

  {
    id: 7,
    cover: "../images/list/ni1.jpeg", // Main image
    images: [
      "../images/list/ni2.jpeg",
      "../images/list/ni3.jpeg",
      "../images/list/ni4.jpeg",
      "../images/list/ni5.jpeg",
      "../images/list/ni6.jpeg",
      
      
    ], // Additional images
    name: "Apartments  in Kilimani ",
    location: "Kilimani, Nairobi",
    category: "For Sale",
    price: "From Ksh. 8.8M",
    type: "",
    features: [
      "Comprises of......",
      "  2 Beds  (87.01 sqm to 111.14 sqm from 8.8M to 11.8M)",
      "  3 Beds  (122.06 sqm from 11M to 12M)",
     
      "Other Features(Kichen fitted with Hood, Oven and Cooker, Proximity to Malls, Schools, Hospitals, Clean mother Title  )",
     
      "AMENITIES (Intercom, High tech security Features,children play Area, Elegant Modern Gym, Heated Swimming Pool, Back up Generator, Borehole & City Council Water, Ample Parking,CCTV Survrilance)",
    ],
  },

  {
    id: 8,
    cover: "../images/list/sh1.jpeg", // Main image
    images: [
      "../images/list/sh2.jpeg",
      "../images/list/sh3.jpeg",
      "../images/list/sh4.jpeg",
      
      "../images/list/sh6.jpeg",
      "../images/list/sh7.jpeg",
      
      
    ], // Additional images
    name: "Modern Apartments in Kileleshwa [Ideal for Family] ",
    location: "Kileleshwa, Nairobi",
    category: "For Sale",
    price: " Ksh. 22M",
    type: "",
    features: [
      "Comprises of......",
      "  4 Beds Ensuite plus DSQ (230 sqm For 22M)" ,
      
     
      "Other Features(Proximity to malls, Schools, Hospital, FLEXIBLE Payment Plan[instalments], Bank morgage option available, Clean mother title )",
     
      "AMENITIES (Intercom,children play Area, Elegant Modern Gym, Heated Swimming Pool, Back up Generator, Borehole & City Council Water, Ample Parking,CCTV Survrilance)",
    ],
  },

  {
    id: 9,
    cover: "../images/list/ro1.jpeg", // Main image
    images: [
      "../images/list/ro2.jpeg",
      "../images/list/ro3.jpeg",
      "../images/list/ro4.jpeg",
      "../images/list/ro5.jpeg",
      "../images/list/ro6.jpeg",
      "../images/list/ro7.jpeg",
      
      
    ], // Additional images
    name: "Located in Westland along Westland Road ",
    location: "Westlands Road, Nairobi",
    category: "For Sale",
    price: "From  Ksh. 9.12M",
    type: "",
    features: [
      "Comprises of......",
      "  1 Bedroom  (58 sqm from 9.12M)",
      "  2 Bedrooms  (94 sqm from 15M)",
      "payment plan that spreads towards the completion [ October 2026]",
      
     
      "Other Features(4 banners, extractor, a closet and oven )",
     
      "AMENITIES (Heated swimming pool, 240 sq meters fully equipped gym with modern and sophisticated equipments, High speed lift per block, In house kids playing area, A sauna, In house coffee shop, Mini golf course at the rooftop of block B, Lounge area on the rooftop, Ample parking slots)",
    ],
  },

  {
    id: 10,
    cover: "../images/list/vi1.jpeg", // Main image
    images: [
      "../images/list/vi2.jpeg",
      "../images/list/vi3.jpeg",
      "../images/list/vi4.jpeg",
      "../images/list/vi5.jpeg",
      "../images/list/vi8.jpeg",
      "../images/list/vi6.jpeg",
      "../images/list/vi7.jpeg",
      "../images/list/vi9.jpeg",
      "../images/list/vi10.jpeg",
      
    ], // Additional images
    name: "Located in Lavington, Nairobi along Kingara road with convenient access from Ngong road and Gitanga Way,  offering STUDIOS, 1BR, 2BR.  Completion date is July 2026.",
    location: "Lavington, Nairobi",
    category: "For Sale",
    price: " From Ksh. 4.89M",
    type: "",
    features: [
      "The Price is as follows: WITH FITTED KITCHEN AND BIG BALCONY",
      "  1 Bedroom  (64 sqm from 6.73M)",
      "  1 Bedroom + Study room (73.45 sqm from 7.68M)",
      "  2 Bedrooms  (97.96 sqm from 10.09M)",
      " Studio (44.79 sqm from 4.89M)",
      
     
      "Other Features(Proximity to malls, Schools, Hospital, FLEXIBLE Payment Plan[instalments], Bank morgage option available, Clean mother title )",
     
      "AMENITIES (Roof garden,Fully equipped gym , Indoor Kids Play area, Heated Swimming Pool, Intelligent Lock, High-speed lifts, Ample Parking with 3 basement levels,CCTV Survrilance,  ⁠Professional security team)",
      "PAYMENT PLAN....",
      "Cash Payment for 2br- 20% deposit , 80% within 90 days",
      "Installments for 2br- from 20% deposit balance within completion period",
      "Installments for 1br- from 30% deposit balance within completion period",
      "Installments for Studio- from 40% deposit balance within completion period",

    ],
  },

  {
    id: 11,
    cover: "../images/list/st6.jpeg", // Main image
    images: [
      "../images/list/st2.jpeg",
      "../images/list/st3.jpeg",
      "../images/list/st4.jpeg",
      "../images/list/st5.jpeg",
      "../images/list/st1.jpeg",
      
      
    ], // Additional images
    name: "Located in Westlands on Muthithi Road, this top seller listing features studio, 1-bedroom, and 2-bedroom apartments for sale. It is ideal for Investment. ",
    location: "Westlands, Nairobi",
    category: "For Sale",
    price: "From Ksh. 5.8M",
    type: "",
    features: [
      "UNITS PRICES ......",
      "  1 Bedroom  (55-57 sqm from 7M)",
      "  2 Bedrooms  (97-103 sqm from 12M)",
      " Studio (47 sqm from 5.8M)",
     
     
     
      "AMENITIES (Intercom, Manned security and electric fence, children play Area, Rooftop 360 glass windows equipped gym rooftop coffee bar and BBQ area laundry mat, Rooftop Swimming Pool, Back up Generator, Borehole & City Council Water, Ample Parking,CCTV Survrilance)",
      "PAYMENT PLAN....",
      "30% deposit balance in installments till completion, ou can reserve with 10% only ",
    ],
  },

];


export const awards = [
  
]
export const location = [
  {
    id: 1,
    name: "Kilimani, Kenya",
    Villas: "12 Family House",
    Apartments: "10 Apartments",
    Offices: "07 Villa",
    cover: "./images/location/city-.png",
  },
  {
    id: 2,
    name: "Lavington, Kenya",
    Villas: "12 Family House",
    Apartments: "10 Apartments",
    Offices: "07 Villa",
    cover: "./images/location/lav.png",
  },
  {
    id: 3,
    name: "Westlands, Kenya",
    Villas: "12 Family House",
    Apartments: " 10 Apartments",
    Offices: "07 Villa",
    cover: "./images/location/Westlands.png",
  },
  
]
export const team = [
  
]
export const price = [
  
]
export const footer = [

 
]

