import React from "react"
import Back from "../common/Back"
import Heading from "../common/Heading"
import img from "../images/about.jpg"
import "./about.css"

const About = () => {
  return (
    <>
      <section className='about'>
        <Back name='About Us' title='About Us - Who We Are?' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='Our  Story' subtitle='Check out our company story ' />

            <p>Daze Property is more than just a real estate agency; it's a community of dedicated professionals who believe in building lasting relationships with our clients. We are passionate about helping individuals and families find the perfect property, whether it's a cozy home for first-time buyers, a luxury estate for seasoned homeowners, or a lucrative commercial property for investors. With a keen understanding of market trends and a commitment to personalized service, we guide our clients through every step of the buying, selling, or renting process..</p>
          
            
          </div>
          <div className='right row'>
            <img src='./immio.jpg' alt='' />
          </div>
        </div>
      </section>
    </>
  )
}

export default About
